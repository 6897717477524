$(function() {
    $.scrollify({
        section: ".scene",
        scrollSpeed: 500,
        easing: "easeOutCirc",
        before: function () {
            var cur_scene = $.scrollify.current();
            var id = cur_scene.attr('id');
            if(id == 'scene1') {
                $('.btl1').css('zIndex', '777');
                $('.btls div:not(.btl1)').css('zIndex', '77');
                $('.btl1').delay(450).animate({
                    height: '80vh',
                }, 700, 'easeOutCirc', function() {
                    $('.btls div:not(.btl1)').css('height', '0px');
                });
            }
            if(id == 'scene2') {
                $('.btl2').css('zIndex', '777');
                $('.btls div:not(.btl2)').css('zIndex', '77');
                $('.btl2').delay(450).animate({
                    height: '80vh',
                }, 700, 'easeOutCirc', function() {
                    $('.btls div:not(.btl2)').css('height', '0px');
                });
            }
            if(id == 'scene3') {
                $('.btl3').css('zIndex', '777');
                $('.btls div:not(.btl3)').css('zIndex', '77');
                $('.btl3').delay(450).animate({
                    height: '80vh',
                }, 700, 'easeOutCirc', function() {
                    $('.btls div:not(.btl3)').css('height', '0px');
                });
            }
            if(id == 'scene4') {
                $('.btl4').css('zIndex', '777');
                $('.btls div:not(.btl4)').css('zIndex', '77');
                $('.btl4').delay(450).animate({
                    height: '80vh',
                }, 700, 'easeOutCirc', function() {
                    $('.btls div:not(.btl4)').css('height', '0px');
                });
            }
        },
    });
});